import { ProtocolInterface } from "../UI/Protocol/Protocol"

export interface Node {
  ip: string;
  name: string;
}

export interface Resolution {
  ip: string;
  port: string;
  name: string;
  namespace: string;
  // eslint-disable-next-line
  pod?: any;
  // eslint-disable-next-line
  endpointSlice?: any;
  // eslint-disable-next-line
  service?: any;
  resolutionMechanism?: string
}

export enum CaptureErrorType {
  DissectionError = 0,
  ConnectionError = 1,
  TimeoutError = 2,
}

export interface CaptureError {
  type: CaptureErrorType,
  msg: string;
}

export interface KsEvent {
  source: string;
  type: string;
  // eslint-disable-next-line
  data: any;
}

export interface Entry {
  key: string;
  id: string;
  index?: number;
  stream: string;
  node: Node;
  worker: string;
  proto: ProtocolInterface;
  tls: boolean;
  method?: string;
  methodQuery?: string;
  summary: string;
  summaryQuery: string;
  status?: number;
  statusQuery?: string;
  timestamp: Date;
  src: Resolution;
  dst: Resolution;
  outgoing: boolean;
  requestSize: number;
  responseSize: number;
  elapsedTime: number;
  passed: boolean;
  failed: boolean;
  record: string;
  event?: KsEvent;
  error: CaptureError;
  duplicate: string;
  size: number;
}

export interface TableData {
  name: string;
  value: string | number;
  displayedValue?: string | number;
  selector: string;
}

export interface SectionData {
  type: string;
  title: string;
  tableData: TableData[];
  encoding: string;
  mimeType: string;
  body: string;
  selector: string;
}

export interface Representation {
  request: SectionData[];
  response: SectionData[];
  event: SectionData[];
  data: SectionData[];
}
