import { useNavigate, useSearchParams } from 'react-router-dom'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import queryAtom from '../recoil/query/atom'
import queryBuildAtom from '../recoil/queryBuild/atom'
import queryBackgroundColorAtom from '../recoil/queryBackgroundColor/atom'
import { ColorYellow, LocalStorageKey } from '../consts'
import { useEffect, useState } from 'react'
import useQueryFilters from './useQueryFilters'
import trafficPlaybackAtom from '../recoil/trafficPlayback'
import { useAuth } from '../components/UI/Auth/Auth'
import { TRAFFIC_PLAYBACK_OK } from '../recoil/trafficPlayback/atom'

const useQueryRestore = (): void => {
  const [readyToRestore, setReadyToRestore] = useState(false)
  const setQuery = useSetRecoilState(queryAtom)
  const setQueryBuild = useSetRecoilState(queryBuildAtom)
  const setQueryBackgroundColor = useSetRecoilState(queryBackgroundColorAtom)

  const { queryFilters, fetchQueryFilters } = useQueryFilters()

  const navigate = useNavigate()

  const [searchParams] = useSearchParams()

  const trafficPlayback = useRecoilValue(trafficPlaybackAtom)

  const { shouldAuthenticate } = useAuth()

  useEffect(() => {
    if (trafficPlayback !== TRAFFIC_PLAYBACK_OK) {
      return
    }

    if (shouldAuthenticate) {
      return
    }

    fetchQueryFilters()
  }, [trafficPlayback])

  useEffect(() => {
    if (queryFilters.fetched) {
      setReadyToRestore(true)
    }
  }, [queryFilters])

  useEffect(() => {
    if (!readyToRestore) {
      return
    }

    const shouldRestoreQuery = localStorage.getItem(
      LocalStorageKey.ShouldRestoreQuery
    )
    localStorage.removeItem(LocalStorageKey.ShouldRestoreQuery)

    const querySearchParam = searchParams.get('q')
    searchParams.delete('q')

    const resolvedQuery = shouldRestoreQuery ||
      (querySearchParam === null ? queryFilters.default : querySearchParam)

    searchParams.append('q', resolvedQuery)

    if (resolvedQuery.length > 0) {
      setQueryBuild(resolvedQuery)
      setQuery(resolvedQuery)
      setQueryBackgroundColor(ColorYellow)
      navigate({
        pathname: location.pathname,
        search: searchParams.toString()
      })
    }
  }, [readyToRestore])
}

export default useQueryRestore
