import React from 'react'
import { SettingSection } from '../../SettingSection/SettingSection'
import { CircuitBoardIcon } from '../../../../UI/Icons/CircuitBoardIcon'
import ViewInArRoundedIcon from '@mui/icons-material/ViewInArRounded'
import PowerSettingsNewRoundedIcon from '@mui/icons-material/PowerSettingsNewRounded'
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded'
import variables from '../../../../../variables.module.scss'
import PowerOffRoundedIcon from '@mui/icons-material/PowerOffRounded';
import { Alert, AlertTitle, Box } from '@mui/material'
import {
  DisableTrafficCapturingButton
} from '../../../../TrafficViewer/DisableTrafficCapturingButton/DisableTrafficCapturingButton'
import {
  LinkCaptureFilters,
  LinkKflSyntaxReference,
  LinkProtocolDissectors,
  LinkTrafficCapture
} from '../../../../../consts'
import {
  appDissectorsUpdatingEnabled, appPresetFiltersChangingEnabled,
  appStopTrafficCapturingDisabled,
  appTargetedPodsUpdateDisabled
} from '../../../../../types/global'
import { useRecoilState, useRecoilValue } from 'recoil'
import packetCapturingStoppedAtom from '../../../../../recoil/packetCapturingStopped'
import trafficSettingsModalOpenAtom from '../../../../../recoil/trafficSettingsModalOpen'
import { InlineCode } from '../../../../UI/InlineCode/InlineCode'

import { CaptureFiltersView } from './CaptureFiltersView/CaptureFiltersView'
import { EnabledDissectorsView } from './EnabledDissectorsView/EnabledDissectorsView'
import { QueryFiltersView } from './QueryFiltersView/QueryFiltersView'
import { SettingDisabledCard } from '../../SettingDisabledCard/SettingDisabledCard'

export const RootView: React.FC = () => {
  const capturingStopped = useRecoilValue(packetCapturingStoppedAtom)
  const [modalOpen, setModalOpen] = useRecoilState(trafficSettingsModalOpenAtom)

  return (
    <>
      <Box
        display='flex'
        alignItems='stretch'
        flexGrow={1}
        width='100%'
        sx={{ flexFlow: 'column nowrap' }}
      >
        <SettingSection
          icon={<PowerSettingsNewRoundedIcon htmlColor={variables.blueColor} />}
          title='Traffic Capture'
          description='
            When traffic capture is disabled, Kubeshark remains dormant,
            consuming minimal CPU, memory, and storage resources,
            without capturing or processing any traffic.
          '
          helpLink={LinkTrafficCapture}
          frozen={(!appStopTrafficCapturingDisabled() && (typeof capturingStopped === 'boolean' && capturingStopped))}
          defaultExpanded
        >
          <Box
            display='flex'
            flexDirection='column'
            justifyContent='flex-end'
            flex={1}
            height='100%'>
            {typeof capturingStopped === 'boolean' && capturingStopped && (
              <Box flex={1}>
                <Alert variant='filled' severity='warning' icon={<PowerOffRoundedIcon />} sx={{
                  fontWeight: 400,
                  backgroundColor: variables.warningColor,
                  color: variables.fontColor,
                  mb: '15px',
                }}>
                  <AlertTitle sx={{ color: variables.fontColor }}>Traffic Capture is Disabled!</AlertTitle>
                  <span style={{ color: variables.grayColor }}>
                  Kubeshark is currently dormant.
                  To start capturing and processing traffic, you need to enable traffic capturing.
                  </span>
                </Alert>
              </Box>
            )}
            {!appStopTrafficCapturingDisabled() && (
              <DisableTrafficCapturingButton
                onChange={(stopped: boolean) => {
                  if (!stopped) {
                    setModalOpen(false)
                  }
                }}
              />
            )}
            {appStopTrafficCapturingDisabled() && (
              <SettingDisabledCard>
                <span
                  style={{
                    width: '100%',
                    boxSizing: 'border-box',
                    fontFamily: variables.textFontFamily,
                    fontSize: '14px',
                    fontWeight: 500,
                    lineHeight: 2,
                    color: variables.fontColor
                  }}
                >
                Set&nbsp;<InlineCode>tap.stopTrafficCapturingDisabled</InlineCode>&nbsp;
                to&nbsp;<InlineCode>false</InlineCode>
                &nbsp;to enable/disable traffic capture from UI
                </span>
              </SettingDisabledCard>
            )}
          </Box>
        </SettingSection>
        {(appStopTrafficCapturingDisabled() || (typeof capturingStopped === 'boolean' && !capturingStopped)) && (
          <>
            <SettingSection
              icon={<ViewInArRoundedIcon htmlColor={variables.blueColor} />}
              title='Capture Filters (Pod Targeting)'
              description='
                Capture filters allow targeting specific pods,
                processing only their traffic while ignoring others.
                Using capture filters helps control CPU, memory,
                and storage consumption, while also reducing
                noise from unimportant traffic.
              '
              helpLink={LinkCaptureFilters}
              scrollIntoView={typeof modalOpen === 'object' && modalOpen.settingId === 'capture-filters'}
              defaultExpanded
            >
              {!appTargetedPodsUpdateDisabled() && (
                <CaptureFiltersView />
              )}
              {appTargetedPodsUpdateDisabled() && (
                <SettingDisabledCard>
                  <span
                    style={{
                      width: '100%',
                      boxSizing: 'border-box',
                      fontFamily: variables.textFontFamily,
                      fontSize: '14px',
                      fontWeight: 500,
                      lineHeight: 2,
                      color: variables.fontColor
                    }}
                  >
                  Set&nbsp;<InlineCode>tap.targetedPodsUpdateDisabled</InlineCode>&nbsp;
                  to&nbsp;<InlineCode>false</InlineCode>
                  &nbsp;to set pod targeting rules from UI
                  </span>
                </SettingDisabledCard>
              )}
            </SettingSection>
            <SettingSection
              icon={<CircuitBoardIcon stroke={variables.blueColor} />}
              title='Protocol Dissectors'
              description='Decide which protocol dissectors you need to capture your traffic.'
              helpLink={LinkProtocolDissectors}
              scrollIntoView={typeof modalOpen === 'object' && modalOpen.settingId === 'enabled-dissectors'}
              defaultExpanded
            >
              {appDissectorsUpdatingEnabled() && (
                <EnabledDissectorsView />
              )}
              {!appDissectorsUpdatingEnabled() && (
                <SettingDisabledCard>
                  <span
                    style={{
                      width: '100%',
                      boxSizing: 'border-box',
                      fontFamily: variables.textFontFamily,
                      fontSize: '14px',
                      fontWeight: 500,
                      lineHeight: 2,
                      color: variables.fontColor
                    }}
                  >
                    Set&nbsp;<InlineCode>dissectorsUpdatingEnabled</InlineCode>&nbsp;
                    to&nbsp;<InlineCode>true</InlineCode>
                    &nbsp;to enable/disable dissectors from UI
                  </span>
                </SettingDisabledCard>
              )}
            </SettingSection>
            <SettingSection
              icon={<FilterListRoundedIcon htmlColor={variables.blueColor} />}
              title='Query Filters (KFL)'
              description='
                Global filter - to limit what is visible in the dashboard.
                Default filter - the default dashboard KFL filter.
              '
              helpLink={LinkKflSyntaxReference}
              scrollIntoView={typeof modalOpen === 'object' && modalOpen.settingId === 'query-filters'}
              defaultExpanded
            >
              {appPresetFiltersChangingEnabled() && (
                <QueryFiltersView />
              )}
              {!appPresetFiltersChangingEnabled() && (
                <SettingDisabledCard>
                  <span
                    style={{
                      width: '100%',
                      boxSizing: 'border-box',
                      fontFamily: variables.textFontFamily,
                      fontSize: '14px',
                      fontWeight: 500,
                      lineHeight: 2,
                      color: variables.fontColor
                    }}
                  >
                  Set&nbsp;<InlineCode>tap.presetFiltersChangingEnabled</InlineCode>&nbsp;
                    to&nbsp;<InlineCode>true</InlineCode>
                    &nbsp;to set global/default KFL filters from UI
                  </span>
                </SettingDisabledCard>
              )}
            </SettingSection>
          </>
        )}
      </Box>
    </>
  )
}
